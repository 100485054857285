.contact-us {
	margin: 50px 0;
	background: #f2f4f9b2;
	border-radius: 11px;
}

.contact-us .contact-us-card {
	background: #0057bd;
	border-radius: 11px;
	padding: 32px;
	height: 100%;
}

.contact-us .contact-us-card h4 {
	font-size: 28px;
	font-weight: 600;
	line-height: 32px;
	text-align: left;
	color: #fff;
}

.contact-us .contact-us-card p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: left;
	color: #c9c9c9;
}

.contact-us .contact-us-card ul {
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin-top: 80px;
	padding: 0;
}

.contact-us .contact-us-card ul li {
	display: flex;
	align-items: center;
}

.contact-us .contact-us-card ul li img {
	width: 24px;
	height: 24px;
	margin-right: 24px;
}

.contact-us .contact-us-card ul li span {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: left;
	color: #fff;
}

.contact-us h2 {
	font-size: 40px;
	font-weight: 700;
	line-height: 54.64px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #0057bd;
	margin-bottom: 30px;
}

.contact-us .contact-us-box {
	display: flex;
	flex-direction: column;
	gap: 30px;
	overflow: hidden;
}

.contact-us .contact-us-box input,
.contact-us .contact-us-box textarea {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	color: #0080ff;
	border: none;
	padding: 10px 15px;
	border-radius: 6px;
	outline: none;
}

.contact-us .contact-us-box .contact-us-btn {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
	background: #0057bd;
	border: none;
	outline: none;
	color: #ffffff;
	padding: 10px 20px;
	border-radius: 8px;
}
.contact-us .contact-us-box .contact-us-btn:disabled {
	cursor: no-drop;
	background: #0058bdae;
}
