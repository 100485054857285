.footer {
	background: #0057bd;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 50px 0 16px;
}
.social-networks {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}
.social-networks > a {
	width: 40px;
	height: 40px;
	border: 1px solid #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: #0057bd;
	transition: 0.4s ease;
}
.social-networks > a:hover {
	background: #fff;
}
.social-networks > a:nth-child(1):hover svg g {
	fill: #0057bd;
}
.social-networks > a:nth-child(2):hover svg g {
	fill: #e4405f;
}
.social-networks > a:nth-child(3):hover svg g {
	fill: red;
}
.social-networks > a:nth-child(4):hover svg g {
	fill: #000;
}
.footer-link ul {
	list-style: none;
	display: flex;
	align-items: center;
	gap: 24px;
}
.footer-link ul li a {
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	transition: 0.25s;
}
.footer-link ul li a:hover {
	color: #fff;
	text-shadow: 0.3px 0 0 currentColor;
}
.line {
	width: 80%;
	height: 1.5px;
	background-color: #fff;
	margin-bottom: 30px;
}
.footer-text {
	text-align: center;
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	line-height: 14.06px;
}

@media screen and (max-width: 768px) {
	.footer-link ul li a {
		text-align: center;
	}
}
