.card {
	padding: 20px;
	border-radius: 16px;
	border: 2px solid #e3edf8;
	height: 500px;
	overflow: hidden;
}

.card img {
	width: 243px;
	height: 243px;
	margin: 0 auto 20px;
}

.card-title {
	font-size: 20px;
	font-weight: 700;
	line-height: 27.32px;
	text-align: left;
	color: #0057bd;
	margin-bottom: 8px;
}

.card-desc {
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	text-align: left;
	color: #38506c;
	margin-bottom: 0;
}
