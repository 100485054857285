.container-gallery {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-auto-rows: 100px 300px;
	grid-gap: 20px;
	grid-auto-flow: dense;
}

.gallery-item {
	width: 100%;
	height: 100%;
	position: relative;
}

.gallery-item .image {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.gallery-item .image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
	cursor: pointer;
	transition: 0.5s ease-in-out;
}

.w-1 {
	grid-column: span 1;
}
.w-2 {
	grid-column: span 2;
}
.w-3 {
	grid-column: span 3;
}
.w-4 {
	grid-column: span 4;
}
.w-5 {
	grid-column: span 5;
}
.w-6 {
	grid-column: span 6;
}

.h-1 {
	grid-row: span 1;
}
.h-2 {
	grid-row: span 2;
}
.h-3 {
	grid-row: span 3;
}
.h-4 {
	grid-row: span 4;
}
.h-5 {
	grid-row: span 5;
}
.h-6 {
	grid-row: span 6;
}

@media screen and (max-width: 1200px) {
	.container {
		grid-template-columns: repeat(1, 1fr);
	}
	.w-1,
	.w-2,
	.w-3,
	.w-4,
	.w-5,
	.w-6 {
		grid-column: span 3;
	}
	.h-1,
	.h-2,
	.h-3,
	.h-4,
	.h-5,
	.h-6 {
		grid-row: span 3;
	}
}
@media screen and (max-width: 600px) {
	.container {
		grid-template-columns: repeat(1, 1fr);
	}
	.w-1,
	.w-2,
	.w-3,
	.w-4,
	.w-5,
	.w-6 {
		grid-column: span 12;
	}
}

@keyframes move-down {
	0% {
		top: 10%;
	}
	50% {
		top: 35%;
	}
	100% {
		top: 50%;
	}
}
