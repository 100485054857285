.about-section {
	background: #f2f4f9b2;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 16px;
	padding: 36px;
}

.about-box {
	max-width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
	height: 100%;
}

.about-box h2 {
	font-size: 40px;
	font-weight: 700;
	line-height: 54.64px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #0057bd;
}

.about-box p {
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	text-align: left;
}

/* license */
.license {
	display: flex;
	align-items: center;
	padding: 70px 0 0;
}

.license-box {
	max-width: calc(100% - 641px);
	margin-inline-end: 16px;
}

.license-box h2 {
	font-size: 40px;
	font-weight: 700;
	line-height: 54.64px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #0057bd;
}

.license-box p {
	margin: 50px 0;
}

.license-box p,
.license-box span {
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	text-align: left;
}

.license-box ul {
	list-style: none;
	padding: 0;
	margin: 40px 0 0;
}
.license-box ul li {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.license-box ul li span {
	color: #0057bd;
}

.license-box ul li img {
	margin-right: 16px;
}

.license-img {
	display: flex;
	align-items: center;
	max-width: 50%;
}

@media screen and (max-width: 1200px) {
	.about-section {
		flex-direction: column;
	}
	.about-box {
		max-width: 100%;
	}
	.about-section-img img {
		width: 100%;
	}

	.license {
		flex-direction: column;
	}

	.license-box,
	.license-img {
		max-width: 100%;
		margin-inline-end: 0px;
	}
	.license-img {
		margin-top: 20px;
	}
}
@media screen and (max-width: 770px) {
	.license-img {
		display: flex;
		flex-direction: column;
	}
}
