.language-select-btn {
	cursor: pointer;
	display: flex;
	align-items: center;
	position: relative;
}

.language-selector {
	position: absolute;
	bottom: -95px;
	right: -30%;
	background-color: #fff;
	width: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 11px;
	padding: 5px 0;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.language-select-item {
	padding: 7px 10px;
	border-radius: 11px;
}

.language-select-item:hover {
	background-color: #c1c1c15a !important;
}

.language-select-item-flag {
	margin-right: 16px;
}
