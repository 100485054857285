.hero {
	background: #5c258d;
	background: linear-gradient(180deg, #000f21, #000f21);
	height: calc(100vh - 80px);
	position: relative;
}

.hero-section {
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: calc(100vh - 80px);
	width: 100%;
}

.hero-section-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.hero-section-box {
	max-width: 50%;
}

.hero-section-box p {
	margin: 30px 0;
	color: #fff;
}

.hero-register-btn {
	background: #0057bd;
	color: #fff;
	padding: 10px 20px;
	border-radius: 5px;
	transition: 0.3s ease;
	border: none;
	box-shadow: 0px 4px 36px 0px #0057bd99;
}

.hero-section-img img {
	width: 100%;
	height: calc(100vh - 80px);
}

@media screen and (max-width: 768px) {
	.hero-section-content {
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	.hero-section {
		height: calc(100vh - 180px);
	}
	.hero-section-box {
		max-width: 100%;
	}
	.hero-section-img img {
		width: 350px;
		height: 450px;
	}
}
@media screen and (max-width: 576px) {
	.hero-section-content {
		justify-content: start;
	}
	.hero-section {
		height: calc(100vh - 105px);
		overflow: hidden;
	}
	.hero-section-img img {
		width: 350px;
		height: 400px;
	}
}
