.our-direction {
    background: #F2F4F9B2;
    padding: 50px 0;
}

.our-direction-box>h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 54.64px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #0057BD;
    margin-bottom: 8px;
}

.our-direction-box>p {
    font-size: 20px;
    font-weight: 500;
    line-height: 27.32px;
    text-align: left;
    color: #38506C;
    margin-bottom: 8px;
}