.navbar-content {
	min-height: 70px;
	margin-bottom: 10px;
	position: relative;
}

.navbar .navbar-nav {
	gap: 30px;
}

.navbar-fixed-top {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
	backdrop-filter: blur(16px);
	box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.07);
	transition: 1s ease;
}

.navbar .nav-link {
	color: rgba(255, 255, 255, 0.9);
	font-family: 'Manrope', sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 36px;
	letter-spacing: 0%;
	text-align: left;
	transition: 0.25s;
}

.navbar .nav-link.active {
	color: #fff;
	text-shadow: 0.5px 0 0 currentColor;
}

.register-btn {
	width: 213px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.01em;
	background: #0057bd;
	border-radius: 11px;
	box-shadow: 0px 4px 36px 0px #0057bd99;
	background: #ffffff;
	cursor: pointer;
	margin-left: 20px;
	border: none;
	outline: none;
}
.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: none;
}

@media screen and (max-width: 1400px) {
	.navbar .navbar-nav {
		gap: 0px;
		padding-top: 30px;
	}

	.language-btn {
		display: flex;
		align-items: center;
		justify-content: start;
		margin-bottom: 16px;
	}
	.register-btn {
		margin: 0;
	}

	.navbar .nav-link {
		line-height: 24px;
	}
}
.modal-title {
	font-size: 24px !important;
	font-weight: 700;
	line-height: 54.64px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #0057bd;
	margin-inline-start: 20px;
}
.btn-close {
	margin-inline-end: 20px !important;
}
.modal-body {
	padding: 0 20px;
}
