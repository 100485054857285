.student-life {
	padding: 48px 0;
}
.student-life h2 {
	font-size: 40px;
	font-weight: 700;
	line-height: 54.64px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #0057bd;
	margin-bottom: 32px;
}

.my-header-class {
	font-size: 1.25rem;
	line-height: 2rem;
	height: 4rem;
}
